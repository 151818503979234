import React from "react";
import { Typography } from "@material-ui/core";
import useStyles from "./styles";
import clean from "../../assets/2clean.png";
import postmann from "../../assets/postmann.png";

const Contact = () => {
  const classes = useStyles();
  return (
    <>
      <div className={classes.banner}>
        <div className={classes.wrapper}>
          <div className={classes.card}>
            <h1>Kontakt oss</h1>
            <br />
            <p>Tlf: 91513191</p>
            <br />
            <p>
              Epost: <a href="mailto:post@mrfix.no">post@mrfix.no</a>
            </p>
            <br />
            <p>Adresse: Bjørn farmanns gate 15 3746 SKIEN</p>
          </div>
          <img src={clean} className={classes.img} width={500} />
        </div>
      </div>
      <div className={classes.container}>
        <Typography variant="h2">Frakt:</Typography>
        <div className={classes.mainContent}>
          <Typography variant="h3">Vi leverer med posten</Typography>
          <img
            src={postmann}
            className={classes.mailman}
            alt="Vi leverer med posten"
          />
        </div>
      </div>
    </>
  );
};

export default Contact;
