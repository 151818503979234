import React, { useState } from "react";
import styled from "styled-components";
import {
  AppBar,
  Toolbar,
  IconButton,
  Badge,
  MenuItem,
  Menu,
  Typography,
} from "@material-ui/core";
import { ShoppingCart } from "@material-ui/icons";
import { Link, useLocation } from "react-router-dom";
import RightNav from "./RightNav";
import useStyles from "./styles";

const StyledBurger = styled.div`
  width: 2rem;
  height: 2rem;
  position: relative;
  top: 20px;
  right: 0px;
  z-index: 1002;
  display: none;
  @media (max-width: 930px) {
    display: flex;
    justify-content: space-around;
    flex-flow: column nowrap;
  }
  div {
    width: 2rem;
    height: 0.25rem;
    background-color: ${({ open }) => (open ? "#ccc" : "#ccc")};
    border-radius: 10px;
    transform-origin: 1px;
    transition: all 0.3s linear;
    zindex: 1002;
    &:nth-child(1) {
      transform: ${({ open }) => (open ? "rotate(45deg)" : "rotate(0)")};
    }
    &:nth-child(2) {
      transform: ${({ open }) => (open ? "translateX(100%)" : "translateX(0)")};
      opacity: ${({ open }) => (open ? 0 : 1)};
    }
    &:nth-child(3) {
      transform: ${({ open }) => (open ? "rotate(-45deg)" : "rotate(0)")};
    }
  }
`;

const Burger = ({ totalItems }) => {
  const [open, setOpen] = useState(false);
  const classes = useStyles();
  const location = useLocation();
  return (
    <>
      <div className={classes.flexBurgerNCart}>
        <StyledBurger open={open} onClick={() => setOpen(!open)}>
          <div />
          <div />
          <div />
        </StyledBurger>
        {location.pathname !== "/cart" && (
          <div className={classes.button}>
            <IconButton
              component={Link}
              to="/cart"
              aria-label="Vis handlekurven"
              color="inherit"
            >
              <Badge
                badgeContent={totalItems}
                className={classes.badgeCart}
                color="secondary"
              >
                <ShoppingCart />
              </Badge>
              <Typography className={classes.shoppingcartMenuText}>
                Handlekurv
              </Typography>
            </IconButton>
          </div>
        )}
      </div>

      <RightNav open={open} totalItems={totalItems} />
    </>
  );
};

export default Burger;
