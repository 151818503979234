import React from "react";
import {
  Card,
  CardMedia,
  CardContent,
  CardActions,
  Typography,
  IconButton,
  Button,
  Grid,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import clean from "../../assets/2clean.png";
import useStyles from "./styles";

const Home = () => {
  const classes = useStyles();
  return (
    <>
      <div className={classes.banner}>
        <div className={classes.wrapper}>
          <Grid container justify="center" spacing={4}>
            <main className={classes.mainContentTop}>
              <div>
                <Typography variant="h2" className={classes.header}>
                  Kjøp 2 clean universal nå
                </Typography>
                <Button
                  type="button"
                  component={Link}
                  className={classes.cta}
                  to="/produkter"
                >
                  Til butikken
                </Button>
              </div>
            </main>
          </Grid>

          <img className={classes.img} src={clean} width={500} />
        </div>
      </div>
    </>
  );
};

export default Home;
