import { makeStyles } from "@material-ui/core/styles";
import About from "../../assets/bgAboutUsBanner.png";

export default makeStyles((theme) => ({
  banner: {
    background: "#198754",
    backgroundImage: `url(${About})`,
    backgroundPosition: "top right",
    backgroundRepeat: "no-repeat",
    padding: "50px",
  },
  header: {
    color: "white",
  },
  paragraph: {
    color: "white",
    maxWidth: "500px",
    marginTop: "30px",
  },
  wrapper: {
    display: "flex",
    justifyContent: "space-between",
  },
  cta: {
    background: "#198754",
    padding: "20px",
    color: "white",
    marginTop: "50px",
    fontSize: "20px",
    cursor: "pointer",
    border: "1px solid white",
  },
  container: {
    width: "100%",
    padding: "50px",
  },
  mainContent: {
    display: "flex",
    justifyContent: "space-between",
  },
  mailman: {
    maxWidth: "50%",
  },
  form: {
    background: "white",
    padding: "20px",
    borderRadius: "32px",
  },
  margin: {
    marginTop: "20px",
  },
  card: {
    background: "white",
    padding: "20px",
  },
  img: {
    [theme.breakpoints.up("xs")]: {
      display: "none",
    },
    [theme.breakpoints.up("lg")]: {
      display: "block",
    },
  },
}));
