import React from "react";
import {
  Card,
  CardMedia,
  CardContent,
  CardActions,
  Typography,
  IconButton,
  Button,
  Grid,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import useStyles from "./styles";
import clean from "../../assets/2clean.png";
import postmann from "../../assets/postmann.png";

const About = () => {
  const classes = useStyles();
  return (
    <>
      <div className={classes.banner}>
        <div className={classes.wrapper}>
          <div>
            <Typography className={classes.header} variant="h2">
              Om oss
            </Typography>
            <Typography className={classes.paragraph} variant="body1">
              Mr.fix er et firma fra Skien som spesialiserer seg på
              universalsåpen 2 clean universal. Vi har solgt denne såpen i en
              årrekke. Vi har aggentur på salg av 2 clean universal i Norge.
            </Typography>
            <Typography component={Link} to="/products">
              <button type="button" className={classes.cta}>
                Kjøp såpen
              </button>
            </Typography>
          </div>
          <img src={clean} className={classes.img} width={500} />
        </div>
      </div>
      <div className={classes.container}>
        <Typography variant="h2">Frakt:</Typography>
        <div className={classes.mainContent}>
          <Typography variant="h3">Vi leverer med posten</Typography>
          <img
            src={postmann}
            className={classes.mailman}
            alt="Vi leverer med posten"
          />
        </div>
      </div>
    </>
  );
};

export default About;
