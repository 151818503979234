import { makeStyles } from "@material-ui/core/styles";

export default makeStyles(() => ({
  root: {
    // maxWidth: 345, original width style
    maxWidth: "100%",
  },
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
  },
  cardActions: {
    display: "flex",
    justifyContent: "flex-end",
    background: "#198754",
    width: "120px",
    float: "right",
    marginRight: "20px",
    marginBottom: "20px",
    color: "white",
  },
  cardContent: {
    display: "flex",
    justifyContent: "space-between",
  },
  buyNowCtaText: {
    color: "white",
    marginRight: "10px",
    marginLeft: "10px",
  },
  buyNowCtaIcon: {
    color: "white",
  },
}));
