import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { RotatingLines } from "react-loader-spinner";
import styled from "styled-components";
import { About, Contact, Home } from "./Pages";
import { Navbar, Products, Cart, Checkout } from "./components";
import { commerce } from "./lib/commerce";

const Loader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 100px;
  width: 100%;
  height: 100%;
`;

const App = () => {
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [products, setProducts] = useState([]);
  const [cart, setCart] = useState();
  const [order, setOrder] = useState({});
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(true);

  const fetchProducts = async () => {
    const { data } = await commerce.products.list();

    setProducts(data);
  };

  const fetchCart = async () => {
    setLoading(true);
    setCart(await commerce.cart.retrieve());
    setLoading(false);
  };

  const handleAddToCart = async (productId, quantity) => {
    const item = await commerce.cart.add(productId, quantity);

    setCart(item);
  };

  const handleUpdateCartQty = async (lineItemId, quantity) => {
    setLoading(true);
    const response = await commerce.cart
      .update(lineItemId, { quantity })
      .then((response) => {
        setLoading(false);
        setCart(response);
      });
  };

  const handleRemoveFromCart = async (lineItemId) => {
    const response = await commerce.cart.remove(lineItemId);

    setCart(response);
  };

  const handleEmptyCart = async () => {
    const response = await commerce.cart.empty();

    setCart(response);
  };

  const refreshCart = async () => {
    const newCart = await commerce.cart.refresh();

    setCart(newCart);
  };

  const handleCaptureCheckout = async (checkoutTokenId, newOrder) => {
    setLoading(true);
    try {
      const incomingOrder = await commerce.checkout.capture(
        checkoutTokenId,
        newOrder
      );
      setOrder(incomingOrder);

      refreshCart();
    } catch (error) {
      setErrorMessage(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      await fetchProducts();
      await fetchCart().then(() => {
        setLoading(false);
      });
    };
    fetchData();
  }, []);

  const handleDrawerToggle = () => setMobileOpen(!mobileOpen);

  return (
    <>
      <Router>
        {cart !== undefined ? (
          <Navbar
            total_items={cart.total_items}
            handleDrawerToggle={handleDrawerToggle}
          />
        ) : (
          <Navbar handleDrawerToggle={handleDrawerToggle} />
        )}

        <Switch>
          {loading ? (
            <Loader>
              <RotatingLines
                strokeColor="grey"
                strokeWidth="5"
                animationDuration="0.75"
                width="96"
                visible
              />
            </Loader>
          ) : (
            <>
              <Route exact path="/">
                <Home />
              </Route>
              <Route path="/produkter" exact>
                <Products products={products} onAddToCart={handleAddToCart} />
              </Route>
              <Route exact path="/cart">
                <Cart
                  cart={cart}
                  onUpdateCartQty={handleUpdateCartQty}
                  onRemoveFromCart={handleRemoveFromCart}
                  onEmptyCart={handleEmptyCart}
                />
              </Route>
              <Route path="/checkout" exact>
                <Checkout
                  cart={cart}
                  order={order}
                  onCaptureCheckout={handleCaptureCheckout}
                  error={errorMessage}
                />
              </Route>
              <Route path="/omoss" exact>
                <About cart={cart} />
              </Route>
              <Route path="/kontakt" exact>
                <Contact cart={cart} />
              </Route>
            </>
          )}
        </Switch>
      </Router>
    </>
  );
};
export default App;
