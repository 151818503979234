// import React, { useState } from "react";
// import {
//   AppBar,
//   Toolbar,
//   IconButton,
//   Badge,
//   MenuItem,
//   Menu,
//   Typography,
// } from "@material-ui/core";
// import { ShoppingCart } from "@material-ui/icons";
// import { Link, useLocation } from "react-router-dom";
// import { faBars, faTimes } from "react-icons";
// import logo from "../../assets/logo.png";
// import useStyles from "./styles";

// const PrimarySearchAppBar = ({ totalItems }) => {
//   const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);
//   const classes = useStyles();
//   const location = useLocation();

//   const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

//   const handleMobileMenuClose = () => setMobileMoreAnchorEl(null);

//   const mobileMenuId = "primary-search-account-menu-mobile";

//   const renderMobileMenu = (
//     <Menu
//       anchorEl={mobileMoreAnchorEl}
//       anchorOrigin={{ vertical: "top", horizontal: "right" }}
//       id={mobileMenuId}
//       keepMounted
//       transformOrigin={{ vertical: "top", horizontal: "right" }}
//       open={isMobileMenuOpen}
//       onClose={handleMobileMenuClose}
//     >
//       <MenuItem>
//         <IconButton
//           component={Link}
//           to="/cart"
//           aria-label="Vis handlekurven"
//           color="inherit"
//         >
//           <Badge badgeContent={totalItems} color="secondary">
//             <ShoppingCart />
//           </Badge>
//         </IconButton>
//         <p>Cart</p>
//       </MenuItem>
//       <MenuItem>
//         <Link to="/" className={classes.menuLink}>
//           Hjem
//         </Link>
//       </MenuItem>
//       <MenuItem>
//         <Link to="/products" className={classes.menuLink}>
//           Nettbutikk
//         </Link>
//       </MenuItem>
//       <MenuItem>
//         <Link to="" className={classes.menuLink}>
//           Om oss
//         </Link>
//       </MenuItem>
//       <MenuItem>
//         <Link to="" className={classes.menuLink}>
//           Kontakt oss
//         </Link>
//       </MenuItem>
//     </Menu>
//   );

// return (
//   <>
//     <AppBar position="fixed" className={classes.appBar} color="inherit">
//       <Toolbar>
//         <Typography
//           component={Link}
//           to="/"
//           variant="h6"
//           className={classes.title}
//           color="inherit"
//         >
//           <img
//             src={logo}
//             alt="Logo"
//             height="100px"
//             className={classes.image}
//           />
//         </Typography>

//         {location.pathname === "/" && (
//           <div className={classes.button}>
//             <IconButton
//               component={Link}
//               to="/cart"
//               aria-label="Vis handlekurven"
//               color="inherit"
//             >
//               <Badge
//                 badgeContent={totalItems}
//                 className={classes.badgeCart}
//                 color="secondary"
//               >
//                 <ShoppingCart />
//               </Badge>
//             </IconButton>
//           </div>
//         )}
//       </Toolbar>
//     </AppBar>
//     {renderMobileMenu}
//   </>
// );
// };

import React from "react";
import styled from "styled-components";
import { Link, useLocation } from "react-router-dom";
import {
  AppBar,
  Toolbar,
  IconButton,
  Badge,
  MenuItem,
  Menu,
  Typography,
} from "@material-ui/core";
import Burger from "./Burger";
import logo from "../../assets/logo.png";
import useStyles from "./styles";

const Nav = styled.nav`
  width: 100%;
  height: 15vh;
  padding: 0 0px;
  display: flex;
  background-color: #198754;
  justify-content: space-between;
  .logo {
    padding: 0 0;
  }
`;

const Navbar = ({ total_items }) => {
  const classes = useStyles();
  return (
    <Nav>
      <div className="logo">
        <Typography
          component={Link}
          to="/"
          variant="h6"
          className={classes.title}
          color="inherit"
        >
          <img src={logo} alt="Logo" height="75px" className={classes.image} />
        </Typography>
      </div>
      <Burger totalItems={total_items} />
    </Nav>
  );
};
export default Navbar;

// export default PrimarySearchAppBar;
